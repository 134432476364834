import React, { Component } from 'react';

import * as styles from './footer.module.scss';

class Footer extends Component {
  render() {
    return (
      <section className={styles.footerSection}>
        <p>say Hi@yuliazaru.com</p>
        <p>423 708 2432</p>
      </section>
    );
  }
}

export default Footer;
