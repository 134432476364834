import React, { Component } from 'react';
import { Link } from 'gatsby';

/* Images */
import smallLogo from './images/logo_small.svg';

import * as styles from './Header.module.scss';

class Index extends Component {
  state = {
    displayMobile: false
  };

  toggleMobileNav = () => {
    this.setState({
      displayMobile: !this.state.displayMobile
    });
  };

  renderLogo() {
    if (typeof window !== 'undefined' && window.location.pathname !== '/') {
      return (
        <div className={styles.logoContainer}>
          <Link to="/">
            <img src={smallLogo} alt="logo"/>
          </Link>
        </div>

      );
    }
    return null;
  }

  renderDesktopNav = () => {
    return (
      <div className={styles.mainNav}>
          {this.renderLogo()}
          <span><Link to="/fine-art">FINE ART SERVICE</Link></span>
          <span><Link to="/interiors">INTERIORS</Link></span>
          <span><Link to="/consulting">ART CONSULTING</Link></span>
          <span><Link to="/atmosphere">ATMOSPHERE STYLING</Link></span>
      </div>
    );
  };
  renderMobileList = () => {
    if (this.state.displayMobile) {
      return (
        <ul>
          <li><Link to="/fine-art">FINE ART SERVICE</Link></li>
          <li><Link to="/interiors">INTERIORS</Link></li>
          <li><Link to="/consulting">ART CONSULTING</Link></li>
          <li><Link to="/atmosphere">ATMOSPHERE STYLING</Link></li>
        </ul>
      );
    }
  };

  renderMobileNav = () => {
    return (
      <div className={styles.mainNavMobile}>
        {this.renderLogo()}
        <div className={styles.hamContainer} onClick={this.toggleMobileNav}>&#9776;</div>
        {this.renderMobileList()}
      </div>
    );
  };

  render() {
    return (
      <section className={styles.navigation}>
        {this.renderDesktopNav()}
        {this.renderMobileNav()}
      </section>
    );
  }
}

export default Index;
